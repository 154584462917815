import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'gatsby';
import { LinkItem } from '../interfaces/link';
import { linkStyle } from '../constants';

export interface NavbarBreadcrumbsProps {
  breadcrumbs: LinkItem[];
}
export const NavbarBreadcrumbs = (props: NavbarBreadcrumbsProps) => {
  const { breadcrumbs } = props;
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <Typography
          key={breadcrumb.value}
          sx={{ lineHeight: '40px', ':first-letter': { textTransform: 'uppercase' } }}
        >
          {index != breadcrumbs.length - 1 ? (
            <Link to={breadcrumb.href} style={linkStyle}>
              {breadcrumb.label}
            </Link>
          ) : (
            <Typography key={breadcrumb.value} color="secondary.main">
              {breadcrumb.label}
            </Typography>
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};
