import React from 'react';
import { IconSvg } from './../icon-props';

export const IconTwitter = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '20.901'}
      height={props.height || '16.956'}
      viewBox="0 0 20.901 16.956"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_584"
        data-name="Path 584"
        d="M1330.053,7121.524a8.567,8.567,0,0,0,1.142.061,8.456,8.456,0,0,0,3.963-1.041,9.542,9.542,0,0,0,1.222-.8,4.179,4.179,0,0,1-2.222-.681,4.4,4.4,0,0,1-1.8-2.3,6.218,6.218,0,0,0,.781.06h.661q.48-.1.48-.14a3.3,3.3,0,0,1-.6-.16,4.557,4.557,0,0,1-1.482-.941,4.3,4.3,0,0,1-1.3-3.123,4.185,4.185,0,0,0,1.882.52v-.04a3.27,3.27,0,0,1-.841-.761,4.473,4.473,0,0,1-1-2.862,4.4,4.4,0,0,1,.561-2.062,13.194,13.194,0,0,0,2.862,2.562,11.532,11.532,0,0,0,5.986,1.922,4.552,4.552,0,0,1-.12-.98,4.079,4.079,0,0,1,1.3-3.043,4.252,4.252,0,0,1,3-1.222,4.493,4.493,0,0,1,3.123,1.321,6.6,6.6,0,0,0,.981-.24,9.054,9.054,0,0,0,1.722-.8,4.9,4.9,0,0,1-.921,1.642,6.108,6.108,0,0,1-.9.7v.041a7.224,7.224,0,0,0,2.4-.682h.021a11.162,11.162,0,0,1-.961,1.182,6.912,6.912,0,0,1-1.181,1.022v.759a11.784,11.784,0,0,1-.721,3.885,12.67,12.67,0,0,1-3.564,5.244,12.239,12.239,0,0,1-14.474.961Z"
        transform="translate(-1330.053 -7106.49)"
        fill={props.color ? 'currentColor' : '#fff'}
      />
    </IconSvg>
  );
};
