import React from 'react';
import { IconSvg } from './../icon-props';

export const IconFacebook = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '10.851'}
      height={props.height || '20.92'}
      viewBox="0 0 10.851 20.92"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_583"
        data-name="Path 583"
        d="M1297.261,7125.428v-9.548h-3.183V7112.2h3.183v-2.844a5.229,5.229,0,0,1,.7-2.722,4.369,4.369,0,0,1,4.123-2.122,23.743,23.743,0,0,1,2.843.161v3.3h-1.7q-1.5,0-1.862.661a1.384,1.384,0,0,0-.16.361,3.508,3.508,0,0,0-.1,1.1v2.1h3.684l-.481,3.684h-3.2v9.548Z"
        transform="translate(-1294.078 -7104.508)"
        fill={props.color ? 'currentColor' : '#fff'}
      />
    </IconSvg>
  );
};
