import React from 'react';
import { IconSvg } from './../icon-props';

export const IconLinkedIn = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '19.118'}
      height={props.height || '18.238'}
      viewBox="0 0 19.118 18.238"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_586"
        data-name="Path 586"
        d="M1413.485,7109.153a2,2,0,0,1-.61-1.482,1.94,1.94,0,0,1,.65-1.522,2.475,2.475,0,0,1,1.711-.58,2.225,2.225,0,0,1,1.582.58,2.276,2.276,0,0,1,.7,1.522,1.926,1.926,0,0,1-.661,1.482,2.4,2.4,0,0,1-1.682.6A2.306,2.306,0,0,1,1413.485,7109.153Zm3.733,14.654h-4.124v-12.332h4.124Zm14.775,0h-4.124v-6.566a4,4,0,0,0-.461-2.033,1.658,1.658,0,0,0-1.561-.79,1.978,1.978,0,0,0-1.391.469,3.037,3.037,0,0,0-.751,1.032,1.028,1.028,0,0,0-.1.47v7.418h-4.084v-12.332h4.084v1.762a6.744,6.744,0,0,1,1.221-1.342,3.7,3.7,0,0,1,2.482-.7,4.518,4.518,0,0,1,3.364,1.342,5.831,5.831,0,0,1,1.321,4.224Zm-8.388-10.51v-.06a.053.053,0,0,0-.06.06Z"
        transform="translate(-1412.875 -7105.569)"
        fill={props.color ? 'currentColor' : '#fff'}
      />
    </IconSvg>
  );
};
